/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Fragment } from "react"
import { Footer } from "../components"

import "../assets/css/intro.css"

import LogoPurple from "../assets/svg/hlogo.inline.svg"

function HomePage() {
  return (
    <Fragment>
      <div>
        <div className="absolute z-50 w-12 pt-6 ml-6 md:w-24 md:h-24 md:inset-0 md:mx-auto md:top-25vh md:m-0 md:p-0">
          <LogoPurple />
        </div>

        <Seo title="Hossmann AG" />

        {/* DESKTOP */}
        <section className="hidden bg-gray-800 md:block">
          <div className="flex flex-row">
            <div className="flex-grow w-0 duration-1000 bg-gray-800 intro transition-width">
              <Link
                to="/handwerk/"
                className="relative block h-screen p-10 pr-0"
              >
                <div className="absolute inset-0 z-10 flex flex-col items-center justify-center w-full m-auto">
                  <h1 className="text-5xl text-white lg:text-6xl h1">
                    Handwerk
                  </h1>
                  <div className="w-16 border-b-3 border-secondary"></div>
                </div>
                <StaticImage
                  src="../assets/images/mood/handwerk-leistungen-spachtel-2.jpg"
                  alt=""
                  className="absolute top-0 left-0 z-0 object-cover object-top w-full h-full"
                />
              </Link>
            </div>

            <div className="flex-grow w-0 duration-1000 bg-white intro transition-width">
              <Link
                to="/immobilien/"
                className="relative block h-screen p-10 pl-0"
              >
                <div className="absolute inset-0 z-10 flex flex-col items-center justify-center w-full m-auto">
                  <h1 className="text-5xl text-white lg:text-6xl h1">
                    Immobilien
                  </h1>
                  <span className="w-16 border-b-3 border-secondary"></span>
                </div>
                <StaticImage
                  src="../assets/images/references/dufourstr-zuerich-1.jpg"
                  alt=""
                  className="absolute top-0 left-0 z-0 object-cover object-top w-full h-full"
                />
              </Link>
            </div>
          </div>
        </section>

        {/* MOBILE */}
        <section className="h-screen md:hidden">
          <div className="w-full h-half intro">
            <Link to="/handwerk/">
              <div className="absolute z-10 w-full h-full">
                <h1 className="absolute w-full text-5xl text-center text-white top-25vh h1">
                  Handwerk
                </h1>
              </div>
              <StaticImage
                src="../assets/images/mood/handwerk-leistungen-spachtel-2.jpg"
                alt=""
                className="object-cover object-center h-full"
              />
            </Link>
          </div>

          <div className="w-full h-half intro">
            <Link to="/immobilien/">
              <div className="absolute z-10 w-full h-half">
                <h1 className="absolute w-full text-5xl text-center text-white top-25vh h1">
                  Immobilien
                </h1>
              </div>
              <StaticImage
                src="../assets/images/references/dufourstr-zuerich-1.jpg"
                alt=""
                className="object-cover object-center h-full"
              />
            </Link>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  )
}

export default HomePage
